import Cookies from "js-cookie";
const user = {
  state: {
    user: Cookies.get("user") || "",
    token: localStorage.getItem("token") || "",
    endpoints: {
      obtainJWT: "",
      refreshJWT: "",
    },
  },
  mutations: {
    login(state, loginData) {
      state.user = loginData.user;
      state.token = loginData.token;
      state.id = loginData.id;

      localStorage.setItem("id", loginData.id);
      localStorage.setItem("user", loginData.user);
      localStorage.setItem("nickname", loginData.nickname);
      localStorage.setItem("token", loginData.token);
    },
    logout(state) {
      // 清除登录数据
      state.token = "";
      state.user = "";
      Cookies.remove("user");

      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("nickname");
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
  },
};

export default user;
