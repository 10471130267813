import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/Login"),
  },

  {
    path: "/",
    component: () => import("@/layout"),
    redirect: "/overview",
    children: [
      {
        path: "overview",
        name: "overview",
        component: () => import("@/views/Overview/Overview"),
      },
      {
        path: "home",
        name: "home",
        component: () => import("@/views/workspace/Home"),
      },
    ],
  },
  {
    path: "/sign",
    name: "sign",
    component: () => import("@/views/workspace/Sign"),
  },
  {
    path: "/calender",
    name: "calender",
    component: () => import("@/views/workspace/Calender"),
  },
];

const originalPush = VueRouter.prototype.push;

// 解决重复路由报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(({ name }, from, next) => {
  // const title = to.meta && to.meta.title;
  // if (title) {
  //   document.title = title;
  // }
  // 获取 JWT Token
  if (localStorage.getItem("token")) {
    // 如果用户在login页面
    if (name === "login") {
      next("/");
    } else {
      next();
    }
  } else {
    if (name === "login") {
      next();
    } else {
      next({ name: "login" });
    }
  }
  // next();
});
export default router;
